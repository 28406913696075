import { injectable, inject } from 'inversify';
import { Brand } from '@uniti-internet/promotions-js-client';
import { Catalog, Promotion, ServiceDetails } from '@uniti-internet/promotions-js-client/dist/utilities/types';
import { PromotionsHandlerInterface } from './promotionsHandlerInterface';
import { Product } from '@/adapters/catalogAdapterInterface';
import { Service } from '@/adapters/serviceQualificationAdapterInterface';
import { PromotionsAdapterInterface } from '@uniti-internet/promotions-js-client/dist/adapters/promotionsAdapterInterface';

@injectable()
export class PromotionsHandler implements PromotionsHandlerInterface {
  constructor (
    @inject('PromotionsAdapter') private promotionsClient: PromotionsAdapterInterface
  ) { }

  public async suggest (sq: Service, brand: Brand, catalogs: Catalog[], code?: string): Promise<Promotion|undefined> {
    let promotions: Promotion[] = [];
    if (code) {
      promotions = await this.promotionsClient.suggest(sq, brand, catalogs, code);
      if (promotions.length > 0) {
        return promotions[0];
      } else {
        return undefined;
      }
    } else {
      promotions = await this.promotionsClient.suggest(sq, brand, catalogs);
      if (promotions.length > 0) {
        return promotions[0];
      } else {
        return undefined;
      }
    }
  }

  public async validate (sq: Service, products: Product[], brand: Brand, code: string, catalogs: Catalog[], serviceDetails: ServiceDetails): Promise<Promotion|undefined> {
    const validPromo = await this.promotionsClient.validate(sq, products, brand, code, catalogs, serviceDetails);
    if (validPromo.length > 0) {
      return validPromo[0];
    } else {
      return undefined;
    }
  }

  public async isContractLengthValid (products: Product[], code: string, brand: Brand): Promise<boolean> {
    return await this.promotionsClient.isContractLengthValid(products, code, brand);
  }

  public async isPlanValid (products: Product[], code: string, brand: Brand): Promise<boolean> {
    return await this.promotionsClient.isPlanValid(products, code, brand);
  }

  public mapActionsToProducts (suggestedPromotion: Promotion, products: Product[]): Product[] {
    const validCodes = ['FREE-CONTRACT', 'PERCENT', 'RECURRING', 'FREE-ROUTER', 'FREE-ACTIVATION', 'FREE-NDC', 'DELAYED-NDC', 'YEAR-OFF', 'ROUTER-DISCOUNT'];

    products = products.map(product => {
      for (const action of suggestedPromotion.actions) {
        const code = action.actionCatalogObject.code ?? '';

        if (!validCodes.includes(code)) continue;

        if (code === 'PERCENT' || code === 'ROUTER-DISCOUNT') {
          if (action.applicableTo.includes(product.id)) {
            product.discountedPrice = this.calculatePercentageOffPrice(product.price, action.actionCatalogObject.price);
            product.discounted = true;
          }
        }

        if (code === 'RECURRING') {
          if (action.applicableTo.includes(product.id)) {
            product.discountedPrice = Math.round((product.price + action.actionCatalogObject.price) * 100) / 100;
            product.discounted = true;
          }
        }

        if (code === 'FREE-CONTRACT' || code === 'FREE-ACTIVATION' || code === 'FREE-NDC' || code === 'FREE-ROUTER' || code === 'DELAYED-NDC' || code === 'YEAR-OFF') {
          if (action.applicableTo.includes(product.id)) {
            product.discountedPrice = 0;
            product.discounted = true;
          }
        }
      }
      return product;
    });
    return products;
  }

  public addDisplayDiscountConditionToProducts (products: Product[]): Product[] {
    products = products.map(product => {
      if (product.discounted) {
        product.displayDiscount = true;
      }
      return product;
    });

    return products;
  }

  public removeDisplayDiscountConditionFromProducts (products: Product[]): Product[] {
    products = products.map(product => {
      if (product.discounted) {
        product.displayDiscount = false;
      }
      return product;
    });

    return products;
  }

  public removeDiscountsFromProducts (products: Product[]): Product[] {
    products = products.map(product => {
      if (product.discounted) {
        delete product.discounted;
        delete product.discountedPrice;
        delete product.displayDiscount;
      }
      return product;
    });

    return products;
  }

  public applyApplicableWhenConditionToProducts (suggestedPromotion: Promotion, selectedProducts: Product[], products: Product[]): void {
    const productsWithActionsApplied: string[] = [];
    const filteredActions = suggestedPromotion.actions.filter(action => action.applicableWhen.length > 0);

    for (const action of filteredActions) {
      // we check the selected products to make sure an Id matches the applicable when id's
      const selectedProductsFiltered = selectedProducts.filter(product => {
        for (const actionId of action.applicableWhen) {
          return product.id === actionId;
        }
      });
      // if no products match the discount action is not valid we remove the discounts
      // unless we have already applied an action and then skip the current action
      if (selectedProductsFiltered.length === 0) {
        for (const productId of action.applicableTo) {
          let product = products.find(product => product.id === productId);
          if (product && !productsWithActionsApplied.includes(product.id)) {
            [product] = this.removeDiscountsFromProducts([product]);
          }
        }
        continue;
      }

      for (const productId of action.applicableTo) {
        // Find the first product that has the same Id as the applicableToId
        let product = products.find(product => product.id === productId);

        if (product) {
          // remove existing discounts if applicable when discount should be applied
          [product] = this.removeDiscountsFromProducts([product]);

          if (action.applicableTo.includes(product.id)) {
            if (action.actionCatalogObject.code === 'PERCENT' || action.actionCatalogObject.code === 'ROUTER-DISCOUNT') {
              if (action.applicableTo.includes(product.id)) {
                product.discountedPrice = this.calculatePercentageOffPrice(product.price, action.actionCatalogObject.price);
                product.discounted = true;
                product.displayDiscount = true;
                productsWithActionsApplied.push(product.id);
              }
            }
            if (action.actionCatalogObject.code === 'FREE-ROUTER') {
              if (action.applicableTo.includes(product.id)) {
                product.discountedPrice = 0;
                product.discounted = true;
                product.displayDiscount = true;
                productsWithActionsApplied.push(product.id);
              }
            }
          }
        }
      }
    }
  }

  private calculatePercentageOffPrice (price: number, percentage: number) {
    return price - price * Math.abs(percentage) / 100;
  }
}
