<template>
  <div>
    <div class="container mx-auto px-10 sm:px-8">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">

</script>
