import { inject, injectable } from 'inversify';
import { DevelopmentSqServiceInterface } from './developmentSqServiceInterface';
import { HttpClientInterface } from './integration/http/httpClientInterface';
import { AutoCompletedAddress } from './integration/clients/types';

@injectable()
export class DevelopmentSqService implements DevelopmentSqServiceInterface {
  constructor (@inject('SqHttpClient') private httpClient: HttpClientInterface) { }

  public async sqByDevelopment (developments: Array<string>): Promise<Array<AutoCompletedAddress>> {
    // Url endpoint to receive all addresses from SQ for a specific development
    let url = 'api/v1/address?';

    // building request url
    developments.forEach(development => {
      url = url + '&development[]=' + development.toUpperCase();
    });

    const response: { data: AutoCompletedAddress[] } = await this.httpClient.get(url);

    return response.data;
  }

  public filterByAddress (data: AutoCompletedAddress[], partialAddress: string): AutoCompletedAddress[] {
    // filtering returned results with partialAddress
    const filtered = data.filter(element => {
      return element.address.toLowerCase().includes(partialAddress);
    });

    return filtered;
  }
}
