import { injectable } from 'inversify';
import { Brand, Carrier } from '../integration/enums';
import { BrandServiceInterface, HelpOption, PrivacyInformation, VoipInformation, PlanSelectorProductInformation, DefaultPlanSelectorProducts, FeaturesData, LegalDocuments } from './brandServiceInterface';

/**
 * Brand-specific logic for UNI.
 */
@injectable()
export class BrandService implements BrandServiceInterface {
  public getName (): Brand {
    return Brand.UNI;
  }

  public getLogo (): string {
    return this.getAssetsBaseUrl() + `/${this.getName()}/${this.getName().toLowerCase()}-logo.png`;
  }

  public getFavicon (): string {
    return this.getAssetsBaseUrl() + `/${this.getName()}/${this.getName().toLowerCase()}-favicon.png`;
  }

  public getTitle (): string {
    return 'Default Brand';
  }

  public getHeroTitle (): string {
    return 'Default Hero Tagline.';
  }

  public getHomeAnnouncement (): string {
    return '';
  }

  public getHeroImage (): string {
    return this.getAssetsBaseUrl() + `/${this.getName()}/${this.getName().toLowerCase()}-hero.png`;
  }

  public getContacts (): Array<HelpOption> {
    return [{
      name: 'Call us',
      icon: 'PhoneIcon',
      description: `Call our friendly sales team on <a class="text-blue-500 hover:text-blue-800 hover:underline transition duration-250" href="tel:${this.getSalesPhoneNumber()}">${this.getSalesPhoneNumber()}</a> or our friendly local support team on <a class="text-blue-500 hover:text-blue-800 hover:underline transition duration-250" href="tel:${this.getSupportPhoneNumber()}">${this.getSupportPhoneNumber()}</a>`
    }, {
      name: 'Email Us',
      icon: 'MailIcon',
      description: `Email us at <a class="text-blue-500 hover:text-blue-800 hover:underline transition duration-250" href="mailto:${this.getSupportEmail()}">${this.getSupportEmail()}</a>`
    }, {
      name: 'Moving House?',
      icon: 'HomeIcon',
      description: `Call us at <a class="text-blue-500 hover:text-blue-800 hover:underline transition duration-250" href="tel:${this.getSupportPhoneNumber()}">${this.getSupportPhoneNumber()}</a> so we can assist`
    }];
  }

  public getPrivacyInformation (): PrivacyInformation {
    return {
      brand: 'Default Brand',
      brandPlural: 'Default Brand Plural',
      brandUrl: 'www.brandURL.com',
      policyUrl: 'www.brandURL.com/legal',
      privacyOfficerDetails: {
        email: 'privacy@bloom.com',
        phone: 'unknown #phone',
        businessName: 'Default Brand Limited',
        address: 'Level 1 / 44 Currie Street, Adelaide SA 5000'
      }
    };
  }

  public getVoipInformation (): VoipInformation {
    return {
      email: 'support@default.com.au',
      phone: this.getSupportPhoneNumber(),
      csaAgreementUrl: `https://www.${this.getName()}.com/pdf/blm/Customer%20Service%20Agreement.pdf`
    };
  }

  public getPlanSelectorProductInformation (carrier?: string): PlanSelectorProductInformation {
    return {
      standardFeatures: [
        'Australian Based Support',
        'Open 7 Days'
      ],
      standardLinks: [
        {
          name: 'Data Usage Information',
          link: this.getAssetsBaseUrl() + `/${this.getName()}/data-usage-information.pdf`
        },
        {
          name: 'Critical Information Summary',
          link: this.getAssetsBaseUrl() + `/${this.getName()}/critical-information-summary-internet.pdf`
        },
        {
          name: 'Key Fact Sheet',
          link: this.getAssetsBaseUrl() + `/${this.getName()}/key-fact-sheet.pdf`
        }
      ]
    };
  }

  public removeDefaultPlansFrom () : boolean {
    return false;
  }

  public getDefaultPlanSelectorProducts (): Array<DefaultPlanSelectorProducts> {
    return [{
      id: '',
      name: 'Default The 25',
      description: 'Default description - Perfect for single-person households',
      price: 59.95,
      typicalEveningSpeed: 24.7,
      download: 25,
      upload: 5
    }, {
      id: '',
      name: 'Default The 50',
      description: 'Default description - Perfect for families and households with multiple users',
      price: 69.95,
      typicalEveningSpeed: 48.4,
      download: 50,
      upload: 20
    }, {
      id: '',
      name: 'Default The 100',
      description: 'Default description - Perfect for families and media streaming',
      price: 89.95,
      typicalEveningSpeed: 96.9,
      download: 100,
      upload: 20
    }];
  }

  public getFeaturesData (): FeaturesData {
    return {
      header: {
        headline: 'What sets us apart.',
        tagline: 'Your internet — from a company that believes everyone deserves fast, reliable, fairly priced internet.'
      },
      features: [{
        name: 'Friendly local support',
        description: 'We like to keep it old-school. No lengthy directories or bots, just good old-fashioned customer service!'
      }, {
        name: 'Quick and free installation',
        description: 'Our friendly, local install technicians aim to have you up and running on the same day.'
      }, {
        name: 'Flexible contracts',
        description: 'Our flexible contracts allow you to connect on a month-to-month, 12 or 24 month term.'
      }, {
        name: 'Unlimited data',
        description: 'Unlimited data available for all Broadband plans. Use your data when you want and how you want, no peak or off-peak periods apply.'
      }]
    };
  }

  public getPortalUrl (): string {
    return process.env.VUE_APP_PORTAL_URL;
  }

  public getSupportPhoneNumber (): string {
    return '#supportNumber';
  }

  public getSalesPhoneNumber (): string {
    return '#salesNumber';
  }

  public getRelocationPhoneNumber (): string {
    return '#relocationNumber';
  }

  public getSupportEmail (): string {
    return 'support@brand.com.au';
  }

  public getAssetsBaseUrl (): string {
    return 'https://uniti-bpo-website-assets.assets.unitiinternet.com';
  }

  public getLegalDocuments (): LegalDocuments {
    return {
      termsOfService: [
        {
          name: 'Customer Service Agreement',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/customer-service-agreement.pdf`
        },
        {
          name: 'Service Description',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/service-description.pdf`
        },
        {
          name: 'Direct Debit Service Request Agreement',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/direct-debit-service-request-agreement.pdf`
        },
        {
          name: 'Terms & Conditions',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/terms-and-conditions.pdf`
        }
      ],
      policies: [
        {
          name: 'Fair Use Policy',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/fair-use-policy.pdf`
        },
        {
          name: 'Billing Policy',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/billing-policy.pdf`
        },
        {
          name: 'Complaint Handling Policy',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/complaint-handling-policy.pdf`
        },
        {
          name: 'Financial Hardship Policy',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/financial-hardship-policy.pdf`
        },
        {
          name: 'Performance Guarantee',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/performance-guarantee.pdf`
        },
        {
          name: 'Service Level Agreement',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/service-level-agreement.pdf`
        }
      ],
      formsAndGuides: [
        {
          name: 'Bill Explainer',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/bill-explainer.pdf`
        },
        {
          name: 'IPND Information',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/ipnd-information.pdf`
        },
        {
          name: 'Two Factor Authentication',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/two-factor-authentication.pdf`
        }
      ],
      legacy: [
        {
          name: 'Customer Service Agreement',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/legacy/customer-service-agreement.pdf`
        },
        {
          name: 'Billing Policy',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/legacy/billing-policy.pdf`
        },
        {
          name: 'Direct Debit Service Request Agreement',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/legacy/direct-debit-service-request-agreement.pdf`
        },
        {
          name: 'Fair Use Policy',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/legacy/fair-use-policy.pdf`
        }
      ]
    };
  }

  public getAddress (): string {
    return '';
  }
}
