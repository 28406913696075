import { Carrier } from '../enums';
import { inject, injectable } from 'inversify';
import { HttpClientInterface } from '../http/httpClientInterface';
import { SqClientInterface } from './sqClientInterface';
import { AutoCompletedAddress, ServiceQualification } from './types';

/**
 * @inheritdoc
 */
@injectable()
export class SqClient implements SqClientInterface {
  constructor (@inject('SqHttpClient') protected httpClient: HttpClientInterface) { }

  /**
   * @inheritdoc
   */
  public async searchAddress (address: string | null, gnaf: string | null, carrier: Carrier | null, carrierId: string | null): Promise<Array<ServiceQualification>> {
    // Must provide at least one parameter, and the carrier must be
    // supplied with the carrier id.
    if (!address && !gnaf && ((!carrier && !carrierId) || (carrier && !carrierId) || (!carrier && carrierId))) {
      throw new Error('Must provide at least one parameter for service qualification to search on, and carrier and carrierId must be provided together.');
    }

    // Get parameters.
    const parameters: string[] = [];
    if (carrier && carrierId) {
      parameters.push(`carrier=${carrier}&carrier_id=${carrierId}`);
    }

    if (gnaf) {
      parameters.push(`gnaf=${gnaf}`);
    }

    if (address && (!carrier && !carrierId)) {
      parameters.push(`address=${address}`);
    }

    // Build URL and send.
    const url = 'api/v1/service-qualification?' + parameters.join('&');
    const response: { data: ServiceQualification[] } = await this.httpClient.get(url);

    // Unwrap data.
    return response.data;
  }

  /**
   * @inheritdoc
   */
  public async autoCompleteAddress (partialAddress: string, carrier?: Carrier): Promise<Array<AutoCompletedAddress>> {
    // Must provide the address parameter.
    if (!partialAddress || !partialAddress.length) {
      throw new Error('A partial address must be supplied to SqClient::autoCompleteAddress.');
    }

    // Get parameters.
    const parameters: string[] = [];
    parameters.push(`address=${partialAddress}`);

    if (carrier) {
      parameters.push(`carrier=${carrier}`);
    }

    // Build URL and send.
    const url = 'api/v1/autocomplete?' + parameters.join('&');
    const response: { data: AutoCompletedAddress[] } = await this.httpClient.get(url);

    // Unwrap data.
    return response.data;
  }

  public async getService (id: string | AutoCompletedAddress): Promise<Array<ServiceQualification>> {
    // No empty id pls.
    if (!id) {
      throw new Error('An ID must be supplied to SqClient::getService.');
    }

    if (typeof id !== 'string') {
      id = id.id;
    }

    // Build URL and send.
    const url = `service-qualification/${id}`;
    const response: { data: ServiceQualification } = await this.httpClient.get(url);

    // Unwrap data.
    return [response.data];
  }
}
