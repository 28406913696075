import { injectable } from 'inversify';
import { Brand } from '../integration/enums';
import { BrandService } from './brandService';
import { PrivacyInformation, VoipInformation, PlanSelectorProductInformation, DefaultPlanSelectorProducts, FeaturesData, LegalDocuments } from './brandServiceInterface';

/**
 * Brand-specific logic for UNI.
 */
@injectable()
export class ArklifeBrandService extends BrandService {
  public getName (): Brand {
    return Brand.ARK;
  }

  public getTitle (): string {
    return 'Arklife';
  }

  public getHeroTitle (): string {
    return 'Connect, grow and belong with Arklife';
  }

  public getHomeAnnouncement (): string {
    return '';
  }

  public getPrivacyInformation (): PrivacyInformation {
    return {
      brand: 'Arklife',
      brandPlural: "Arklife's",
      brandUrl: 'order.arklife.com.au',
      policyUrl: 'arklife.com.au/legal',
      privacyOfficerDetails: {
        email: 'privacy@arklife.com.au',
        phone: '1300 956 206',
        businessName: 'Arklife',
        address: ''
      }
    };
  }

  public getVoipInformation (): VoipInformation {
    return {
      email: 'wifisupport@arklife.com.au',
      phone: '1300 956 207',
      csaAgreementUrl: `${this.getAssetsBaseUrl()}/${this.getName()}/customer-service-agreement.pdf`
    };
  }

  public getPlanSelectorProductInformation (): PlanSelectorProductInformation {
    return {
      standardFeatures: [
        'Australian Based Support',
        'Open 7 Days'
      ],
      standardLinks: [
        {
          name: 'Critical Information Summary',
          link: this.getAssetsBaseUrl() + `/${this.getName()}/critical-information-summary-internet.pdf`
        }
      ]
    };
  }

  public removeDefaultPlansFrom (): boolean {
    return true;
  }

  public getDefaultPlanSelectorProducts (): Array<DefaultPlanSelectorProducts> {
    return [{
      id: '',
      name: 'The 50',
      description: 'Perfect for single-person households',
      price: 57.75,
      typicalEveningSpeed: 48.4,
      download: 50,
      upload: 5
    }, {
      id: '',
      name: 'The 100',
      description: 'Perfect for families and households with multiple users',
      price: 79.75,
      typicalEveningSpeed: 96.9,
      download: 75,
      upload: 20
    }, {
      id: '',
      name: 'The 250',
      description: 'Perfect for families and media streaming',
      price: 99.75,
      typicalEveningSpeed: null,
      download: 250,
      upload: 20
    }];
  }

  public getFeaturesData (): FeaturesData {
    return {
      header: {
        headline: 'What makes us different.',
        tagline: 'We are the company that believes everyone deserves fast, reliable, fairly priced internet'
      },
      features: [{
        name: 'Friendly local support',
        description: 'We like to keep it old-school. No lengthy directories or bots, just good old-fashioned customer service!'
      }, {
        name: 'Quick and free installation',
        description: 'Our friendly, local install technicians aim to have you up and running on the same day.'
      }, {
        name: 'No lock-in contracts',
        description: 'Our month-to-month plans give you great flexibility.'
      }, {
        name: 'Unlimited data',
        description: 'Unlimited data available for all Broadband plans. Use your data when you want and how you want, no peak or off-peak periods apply.'
      }]
    };
  }

  public getSupportPhoneNumber (): string {
    return '1300 956 207';
  }

  public getSalesPhoneNumber (): string {
    return '1300 956 206';
  }

  public getRelocationPhoneNumber (): string {
    return '1300 956 207';
  }

  public getSupportEmail (): string {
    return 'wifisupport@arklife.com.au';
  }

  public getLegalDocuments (): LegalDocuments {
    return {
      termsOfService: [
        {
          name: 'Customer Service Agreement',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/customer-service-agreement.pdf`
        },
        {
          name: 'Direct Debit Service Request Agreement',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/direct-debit-service-request-agreement.pdf`
        },
        {
          name: 'Terms & Conditions',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/terms-and-conditions.pdf`
        }
      ],
      policies: [
        {
          name: 'Billing Policy',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/billing-policy.pdf`
        },
        {
          name: 'Financial Hardship Policy',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/financial-hardship-policy.pdf`
        }
      ],
      formsAndGuides: [
        {
          name: 'Authorised Representative',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/authorised-representative.pdf`
        }
      ],
      legacy: []
    };
  }
}
