import { HttpClientInterface } from '@/services/integration/http/httpClientInterface';
import {
  BankAccountInformation,
  CreditCardInformation,
  TokenProviderInterface, TokenResponse
} from '@/services/integration/clients/tokenProviderInterface';
import { injectable } from 'inversify';

type DebitType = 'CREDIT_CARD' | 'BANK_ACCOUNT'

type TokenisationRequest = {
  debitType: DebitType,
  source: string,
  keyToken: string,
  bureauName: string
}

type TokenisationResponse = {
  approved: boolean,
  statusCode: number,
  created: Date,
  debitType: DebitType,
  responseText: string,
  btbToken: string,
}

type CreditCardTokenisationRequest = TokenisationRequest & CreditCardInformation
type BankAccountTokenisationRequest = TokenisationRequest & BankAccountInformation

type CreditCardTokenisationResponse = TokenisationResponse & { ccExpiryDate: string, pan: string }
type BankAccountTokenisationResponse = TokenisationResponse & { accountNumber: string, bsb: string }

type ErrorTokenisationResponse = {
  statusCode: number,
  responseText: string,
  approved: boolean
}

@injectable()
export default class BtbTokenProvider implements TokenProviderInterface {
  private client: HttpClientInterface

  private bureau: string

  private source: string

  constructor (client: HttpClientInterface, bureau: string, source: string) {
    this.client = client;
    this.bureau = bureau;
    this.source = source;
  }

  async getCreditCardToken (request: CreditCardInformation, keyToken: string): Promise<TokenResponse> {
    const creditCardTokenisationRequest: CreditCardTokenisationRequest = {
      ...request,
      debitType: 'CREDIT_CARD',
      keyToken,
      source: this.source,
      bureauName: this.bureau
    };

    const response = await this.client.post<CreditCardTokenisationResponse>('tokenise', {
      json: creditCardTokenisationRequest
    });

    return response.btbToken;
  }

  async getBankAccountToken (request: BankAccountInformation, keyToken: string): Promise<TokenResponse> {
    const bankAccountTokenisationRequest: BankAccountTokenisationRequest = {
      ...request,
      debitType: 'BANK_ACCOUNT',
      keyToken,
      source: this.source,
      bureauName: this.bureau
    };

    const response = await this.client.post<BankAccountTokenisationResponse>('tokenise', bankAccountTokenisationRequest);
    return response.btbToken;
  }
}
