import { injectable } from 'inversify';
import { CartBuilderInterface } from './cartBuilderInterface';
import { Cart, CartContactMedium, CartContactMediumCharacteristic, CartItem, CartProduct, CatalogProductOffering, ServiceQualification } from '../clients/types';
import { Brand, Technology } from '@/services/integration/enums';

@injectable()
export class CartBuilder implements CartBuilderInterface {
  private cart: Cart = {
    relatedParty: [],
    contactMedium: [],
    cartItem: [],
    cartTotalPrice: []
  };

  public createCart (id?: string): CartBuilderInterface {
    this.cart = {
      relatedParty: [],
      contactMedium: [],
      cartItem: [],
      cartTotalPrice: []
    };

    if (id) {
      this.cart.id = id;
    }

    return this;
  }

  setTotalPrice (amount: number): CartBuilderInterface {
    this.cart.cartTotalPrice = [{
      priceType: 'total',
      price: {
        taxIncludedAmount: {
          amount: amount
        }
      }
    }];

    return this;
  }

  addRelatedParty (role: string, name: string): CartBuilderInterface {
    const validRelatedParties = ['Customer', 'Sales Person', 'Location ID', 'Technology', 'Ticket ID', 'Source', 'Dealer', 'Brand', 'OldServiceId', 'Complete', 'RapidAccess', 'Relocation', 'RelocationAccountNumber', 'ActivationDate', 'StaticIp', 'Partner', 'UTM Data', 'CrmId', 'ServiceClass', 'PreOrder'];
    if (validRelatedParties.includes(role)) {
      this.cart.relatedParty.push({
        name: name,
        role: role
      });
    } else {
      throw new Error(`Invalid related party when building cart: ${role}.`);
    }

    return this;
  }

  addContactMedium (type: string, characteristics: Record<string, string>): CartBuilderInterface {
    // Error on invalid contact mediums.
    const validContactMediums = ['Firstname', 'Lastname', 'Sq', 'Address', 'Email', 'Phone', 'BusinessName', 'Abn', 'CardName', 'CardExpiry', 'CardToken', 'ReferralCode', 'ShippingAddress', 'PowerType', 'BuildingHeight', 'RoofType', 'BuildingMaterials'];
    if (validContactMediums.includes(type)) {
      const newContactMedium: CartContactMedium = {
        mediumType: type,
        mediumCharacteristic: {}
      };

      Object.keys(characteristics).forEach(key => {
        const typedKey = key as keyof Record<string, string>;
        const typedKey2 = key as keyof CartContactMediumCharacteristic;
        newContactMedium.mediumCharacteristic[typedKey2] = characteristics[typedKey];
      });

      this.cart.contactMedium.push(newContactMedium);
    } else {
      throw new Error(`Invalid contact medium when building cart: ${type}.`);
    }

    return this;
  }

  addCartItem (name: string, price: number, productSerialNumber: string, term: number, characteristics: Record<string, string>): CartBuilderInterface {
    const newCartItem: CartItem = {
      cartTerm: [],
      itemPrice: [],
      productOffering: []
    };

    newCartItem.cartTerm.push({
      duration: {
        quantity: term
      },
      name: name
    });

    newCartItem.itemPrice.push({
      name: name,
      price: {
        taxIncludedAmount: {
          amount: price
        }
      }
    });

    const newProduct: CartProduct = {
      productSerialNumber: productSerialNumber,
      characteristic: [],
      productTerm: [],
      name: name
    };

    Object.keys(characteristics).forEach(key => {
      const typedKey = key as keyof Record<string, string>;
      newProduct.characteristic.push({
        name: typedKey,
        value: characteristics[typedKey]
      });
    });

    newProduct.productTerm.push({
      name: name,
      duration: {
        quantity: term
      }
    });

    newCartItem.product = newProduct;
    this.cart.cartItem.push(newCartItem);

    return this;
  }

  public setFirstName (name: string): CartBuilderInterface {
    this.addContactMedium('Firstname', {
      firstname: name
    });

    return this;
  }

  public setLastName (name: string): CartBuilderInterface {
    this.addContactMedium('Lastname', {
      lastname: name
    });

    return this;
  }

  public setEmail (email: string): CartBuilderInterface {
    this.addContactMedium('Email', {
      emailAddress: email
    });

    return this;
  }

  public setAddress (street1: string, street2: string, city: string, postCode: string, state: string): CartBuilderInterface {
    this.addContactMedium('Address', {
      street1: street1,
      street2: street2,
      city: city,
      postcode: postCode,
      stateOrProvince: state
    });

    return this;
  }

  public setAddressFromServiceQualification (serviceQualification: ServiceQualification): CartBuilderInterface {
    let street2 = '';
    if (serviceQualification.unit_number) {
      street2 = `${serviceQualification.unit_type} ${serviceQualification.unit_number}`;
    } else if (serviceQualification.level_number) {
      street2 = `${serviceQualification.level_type} ${serviceQualification.level_number}`;
    }

    this.addContactMedium('Address', {
      street1: `${serviceQualification.street_number} ${serviceQualification.street_name} ${serviceQualification.street_type}`,
      street2: street2,
      city: serviceQualification.suburb,
      postcode: serviceQualification.postcode,
      stateOrProvince: serviceQualification.state
    });

    if (serviceQualification.service_class) {
      this.addRelatedParty('ServiceClass', serviceQualification.service_class);
    }

    return this;
  }

  public setPhone (phone: string): CartBuilderInterface {
    this.addContactMedium('Phone', {
      phoneNumber: phone
    });

    return this;
  }

  public setAbn (abn: string): CartBuilderInterface {
    this.addContactMedium('Abn', {
      socialNetworkId: abn
    });

    return this;
  }

  public setCompany (company: string): CartBuilderInterface {
    this.addContactMedium('BusinessName', {
      socialNetworkId: company
    });

    return this;
  }

  public setBrand (brand: Brand): CartBuilderInterface {
    this.addRelatedParty('Brand', brand);
    return this;
  }

  public setDealer (dealer: string): CartBuilderInterface {
    this.addRelatedParty('Dealer', dealer);
    return this;
  }

  public setLocation (location: string): CartBuilderInterface {
    this.addRelatedParty('Location ID', location);
    return this;
  }

  public setPreorder (development: string): CartBuilderInterface {
    // previously charlie parker
    const manualOrders:string[] = [];
    if (development && manualOrders.includes(development.toUpperCase())) {
      this.addRelatedParty('PreOrder', '1');
    }
    return this;
  }

  public setTechnology (technology: Technology): CartBuilderInterface {
    this.addRelatedParty('Technology', technology);
    return this;
  }

  public setActivationDate (activationDate: string): CartBuilderInterface {
    this.addRelatedParty('ActivationDate', activationDate);
    return this;
  }

  public setCrmId (crmId: string | null): CartBuilderInterface {
    if (crmId) {
      this.addRelatedParty('CrmId', crmId);
    }

    return this;
  }

  public setSalesPerson (salesPerson: string): CartBuilderInterface {
    this.addRelatedParty('Sales Person', salesPerson);
    return this;
  }

  public addPlan (plan: CatalogProductOffering, term: number): CartBuilderInterface {
    this.addCartItem(
      plan.name,
      plan.productOfferingPrice[0].price.amount,
      '',
      term,
      {
        symbillId: plan.prodSpecCharValueUse.filter(char => char.valueType === 'SymbillId')[0].productSpecCharacteristicValue[0].value as string,
        type: 'plan'
      }
    );

    return this;
  }

  public addHardware (hardware: CatalogProductOffering): CartBuilderInterface {
    this.addCartItem(
      hardware.name,
      hardware.productOfferingPrice[0].price.amount,
      hardware.prodSpecCharValueUse.filter(char => char.valueType === 'Code')[0].productSpecCharacteristicValue[0].value as string,
      0,
      {
        symbillId: hardware.prodSpecCharValueUse.filter(char => char.valueType === 'SymbillId')[0].productSpecCharacteristicValue[0].value as string,
        type: 'hardware'
      }
    );

    return this;
  }

  public addVoicePlan (voice: CatalogProductOffering): CartBuilderInterface {
    this.addCartItem(
      voice.name,
      voice.productOfferingPrice[0].price.amount,
      voice.name,
      0,
      {
        symbillId: voice.prodSpecCharValueUse.filter(char => char.valueType === 'SymbillId')[0].productSpecCharacteristicValue[0].value as string,
        type: 'voice'
      }
    );

    return this;
  }

  public addActivationFee (fee: CatalogProductOffering): CartBuilderInterface {
    this.addCartItem(
      fee.name,
      fee.productOfferingPrice[0].price.amount,
      fee.prodSpecCharValueUse.filter(char => char.valueType === 'Code')[0].productSpecCharacteristicValue[0].value as string,
      0,
      {
        symbillId: fee.prodSpecCharValueUse.filter(char => char.valueType === 'SymbillId')[0].productSpecCharacteristicValue[0].value as string,
        type: 'activationFee'
      }
    );

    return this;
  }

  public addPromotion (promotion: CatalogProductOffering, code: string): CartBuilderInterface {
    this.addCartItem(
      promotion.name,
      promotion.productOfferingPrice[0].price.amount,
      code,
      0,
      {
        symbillId: promotion.prodSpecCharValueUse.filter(char => char.valueType === 'SymbillId')[0].productSpecCharacteristicValue[0].value as string,
        type: 'promotion'
      }
    );

    return this;
  }

  public getActivationFeePrice (): number|undefined {
    const activationFeeCartItem = this.cart.cartItem.find(cartItem => cartItem.product?.productSerialNumber === 'ACTIVATION-FEE');
    if (activationFeeCartItem) {
      return activationFeeCartItem.itemPrice[0].price.taxIncludedAmount.amount;
    }
    return undefined;
  }

  public getHardwarePrice (): number | undefined {
    const hardwareCartItem = this.cart.cartItem.find(cartItem => cartItem.product?.characteristic.find(c => c.value === 'hardware'));
    if (hardwareCartItem) {
      return hardwareCartItem.itemPrice[0].price.taxIncludedAmount.amount;
    }
    return undefined;
  }

  public getPlanPrice (): number | undefined {
    const planCartItem = this.cart.cartItem.find(cartItem => cartItem.product?.characteristic.find(c => c.value === 'plan'));
    if (planCartItem) {
      return planCartItem.itemPrice[0].price.taxIncludedAmount.amount;
    }
    return undefined;
  }

  public setProductTerm (term: number, productName: string): void {
    const promotionCartItem = this.cart.cartItem.find(cartItem => cartItem.product?.name === productName);
    if (promotionCartItem && promotionCartItem.product) {
      promotionCartItem.product.productTerm[0].duration.quantity = term;
    }
  }

  public getNdcProduct (): CartItem|undefined {
    const ndcProduct = this.cart.cartItem.find(cartItem => cartItem.product?.name === 'New Development Cost');
    if (ndcProduct) {
      return ndcProduct;
    }
    return undefined;
  }

  public addNewDevelopmentCost (fee: CatalogProductOffering): CartBuilderInterface {
    this.addCartItem(
      fee.name,
      fee.productOfferingPrice[0].price.amount,
      fee.prodSpecCharValueUse.filter(char => char.valueType === 'Code')[0].productSpecCharacteristicValue[0].value as string,
      0,
      {
        symbillId: fee.prodSpecCharValueUse.filter(char => char.valueType === 'SymbillId')[0].productSpecCharacteristicValue[0].value as string,
        type: 'ndc'
      }
    );

    return this;
  }

  public complete (): CartBuilderInterface {
    this.addRelatedParty('Complete', '1');
    return this;
  }

  public returnCart (): Cart {
    // If the cart has a first and last name, add a 'Customer' related party.
    // This will create a custoemr record in the DB.
    if (
      this.cart.contactMedium.filter(medium => medium.mediumType === 'Firstname').length &&
      this.cart.contactMedium.filter(medium => medium.mediumType === 'Lastname').length
    ) {
      const first = this.cart.contactMedium.find(medium => medium.mediumType === 'Firstname')?.mediumCharacteristic.firstname;
      const last = this.cart.contactMedium.find(medium => medium.mediumType === 'Lastname')?.mediumCharacteristic.lastname;
      this.addRelatedParty('Customer', `${first} ${last}`);
    }

    // Add the source as this website.
    this.addRelatedParty('Source', 'BPO Website');

    // Finally, complete the cart.
    return this.cart;
  }

  public create (): CartBuilderInterface {
    return new CartBuilder();
  }
}
