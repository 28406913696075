import { HttpClientInterface } from './httpClientInterface';
import ky from 'ky';
import { injectable } from 'inversify';

/**
 * A thin wrapper around the ky.js library.
 */
@injectable()
export class FetchHttpClient implements HttpClientInterface {
  private options = {
    retry: 3,
    prefixUrl: '',
    headers: {
      'content-type': 'application/json'
    }
  };

  constructor (baseUrl = '') {
    this.options.prefixUrl = baseUrl;
  }

  public async get<T> (url: string, options?: Record<string, unknown>): Promise<T> {
    const request = { ...options, ...this.options };
    return await ky.get(url, request).json<T>();
  }

  public async post<T> (url: string, options?: Record<string, unknown>): Promise<T> {
    const request = { ...options, ...this.options };
    return await ky.post(url, request).json<T>();
  }

  public async put<T> (url: string, options?: Record<string, unknown>): Promise<T> {
    const request = { ...options, ...this.options };
    return await ky.put(url, request).json<T>();
  }
}
