import { VueServiceContainer } from './plugins/vueServiceContainerPlugin';
import { FetchHttpClient } from './services/integration/http/fetchHttpClient';
import { HttpClientInterface } from './services/integration/http/httpClientInterface';
import { ArklifeSqClient } from './services/integration/clients/arklifeSqClient';
import { SqClientInterface } from './services/integration/clients/sqClientInterface';
import { CatalogClientInterface } from './services/integration/clients/catalogClientInterface';
import { CatalogClient } from './services/integration/clients/catalogClient';
import { ShoppingCartClientInterface } from './services/integration/clients/shoppingCartClientInterface';
import { ShoppingCartClient } from './services/integration/clients/shoppingCartClient';
import { CartBuilder } from './services/integration/builders/cartBuilder';
import { CartBuilderInterface } from './services/integration/builders/cartBuilderInterface';
import { IntegrationFacade } from './services/integration/integrationFacade';
import { IntegrationFacadeInterface } from './services/integration/integrationFacadeInterface';
import { BrandServiceInterface } from './services/brand/brandServiceInterface';
import { JambaBrandService } from './services/brand/jambaBrandService';
import { HispBrandService } from './services/brand/hispBrandService';
import { ArklifeBrandService } from './services/brand/arklifeBrandService';
import { BloomBrandService } from './services/brand/bloomBrandService';
import { AutocompleteAddressAdapterInterface } from './adapters/autocompleteAddressAdapterInterface';
import { AutocompleteAddressAdapter } from './adapters/autcompleteAddressAdapter';
import { ServiceQualificationAdapterInterface } from './adapters/serviceQualificationAdapterInterface';
import { ServiceQualificationAdapter } from './adapters/serviceQualificationAdapter';
import { CatalogAdapterInterface } from './adapters/catalogAdapterInterface';
import { CatalogAdapter } from './adapters/catalogAdapter';
import { OrderService } from './services/orderService';
import { OrderServiceInterface } from './services/orderServiceInterface';
import { UtilityServiceInterface } from './services/utilityServiceInterface';
import { UtilityService } from './services/unitityService';
import { ProductFilterFactory } from './adapters/productFilters/productFilterFactory';
import { ProductFilterFactoryInterface } from './adapters/productFilters/productFilterFactoryInterface';
import { AuthenticationServiceInterface } from './services/authentication/authenticationServiceInterface';
import { MicrosoftAuthenticationService } from './services/authentication/microsoftAuthenticationService';
import { DevelopmentSqService } from './services/developmentSqService';
import { DevelopmentSqServiceInterface } from './services/developmentSqServiceInterface';
import { PromotionsHandler } from './services/promotionsHandler';
import { PromotionsHandlerInterface } from './services/promotionsHandlerInterface';
import { PromotionsAdapterInterface } from '@uniti-internet/promotions-js-client/dist/adapters/promotionsAdapterInterface';
import { PromotionsAdapter } from '@uniti-internet/promotions-js-client';
import { ServiceQualificationClientFactory } from '@uniti-internet/sq-js-client';
import { ServiceQualificationClient } from '@uniti-internet/sq-js-client/dist/serviceQualificationClient';
import { SqV2Client } from './services/integration/clients/sqV2Client';
import { TokenProviderInterface } from './services/integration/clients/tokenProviderInterface';
import BtbTokenProvider from '@/services/integration/clients/btbTokenProvider';

// Create service container.
const serviceContainer = new VueServiceContainer();

// Bind HTTP clients for integrations.
serviceContainer.bind<ServiceQualificationClient>('SqV2HttpClient').toDynamicValue(() => ServiceQualificationClientFactory.create(process.env.VUE_APP_SERVICE_QUALIFICATION_BASE_URL));
serviceContainer.bind<HttpClientInterface>('SqHttpClient').toDynamicValue(() => new FetchHttpClient(process.env.VUE_APP_SERVICE_QUALIFICATION_BASE_URL));
serviceContainer.bind<HttpClientInterface>('CatalogHttpClient').toDynamicValue(() => new FetchHttpClient(process.env.VUE_APP_CATALOG_BASE_URL));
serviceContainer.bind<HttpClientInterface>('ShoppingCartHttpClient').toDynamicValue(() => new FetchHttpClient(process.env.VUE_APP_SHOPPING_CART_BASE_URL));

// Services.
serviceContainer.bind<PromotionsAdapterInterface>('PromotionsAdapter').toDynamicValue(() => PromotionsAdapter.create(process.env.VUE_APP_PROMOTIONS_BASE_URL));
serviceContainer.bind<DevelopmentSqServiceInterface>('DevelopmentSqServiceInterface').to(DevelopmentSqService);
serviceContainer.bind<CatalogClientInterface>('CatalogClientInterface').to(CatalogClient);
serviceContainer.bind<ShoppingCartClientInterface>('ShoppingCartClientInterface').to(ShoppingCartClient);
serviceContainer.bind<CartBuilderInterface>('CartBuilderInterface').to(CartBuilder);
serviceContainer.bind<IntegrationFacadeInterface>('IntegrationFacadeInterface').to(IntegrationFacade);
serviceContainer.bind<OrderServiceInterface>('OrderServiceInterface').to(OrderService);
serviceContainer.bind<UtilityServiceInterface>('UtilityServiceInterface').to(UtilityService);
serviceContainer.bind<ProductFilterFactoryInterface>('ProductFilterFactoryInterface').to(ProductFilterFactory);
serviceContainer.bind<AuthenticationServiceInterface>('AuthenticationServiceInterface').to(MicrosoftAuthenticationService).inSingletonScope();
serviceContainer.bind<PromotionsHandlerInterface>('PromotionsHandlerInterface').to(PromotionsHandler);
serviceContainer.bind<TokenProviderInterface>('TokenProviderInterface').toDynamicValue(() => new BtbTokenProvider(new FetchHttpClient(process.env.VUE_APP_BTB_TOKEN_BASE_URL), process.env.VUE_APP_BTB_BUREAU, process.env.VUE_APP_BTB_SOURCE));

// Adapters.
serviceContainer.bind<AutocompleteAddressAdapterInterface>('AutocompleteAddressAdapterInterface').to(AutocompleteAddressAdapter);
serviceContainer.bind<ServiceQualificationAdapterInterface>('ServiceQualificationAdapterInterface').to(ServiceQualificationAdapter);
serviceContainer.bind<CatalogAdapterInterface>('CatalogAdapterInterface').to(CatalogAdapter);

// Switching out services & clients depending on the site's brand. Default to JAMBA if no VUE_APP_BRAND_CODE.
if (process.env.VUE_APP_BRAND_CODE === 'JAM') {
  serviceContainer.bind<BrandServiceInterface>('BrandServiceInterface').to(JambaBrandService);
  serviceContainer.bind<SqClientInterface>('SqClientInterface').to(SqV2Client);
} else if (process.env.VUE_APP_BRAND_CODE === 'BLM') {
  serviceContainer.bind<BrandServiceInterface>('BrandServiceInterface').to(BloomBrandService);
  serviceContainer.bind<SqClientInterface>('SqClientInterface').to(SqV2Client);
} else if (process.env.VUE_APP_BRAND_CODE === 'ARK') {
  serviceContainer.bind<SqClientInterface>('SqClientInterface').to(ArklifeSqClient);
  serviceContainer.bind<BrandServiceInterface>('BrandServiceInterface').to(ArklifeBrandService);
} else if (process.env.VUE_APP_BRAND_CODE === 'HIS') {
  serviceContainer.bind<SqClientInterface>('SqClientInterface').to(SqV2Client);
  serviceContainer.bind<BrandServiceInterface>('BrandServiceInterface').to(HispBrandService);
} else {
  serviceContainer.bind<BrandServiceInterface>('BrandServiceInterface').to(JambaBrandService);
  serviceContainer.bind<SqClientInterface>('SqClientInterface').to(SqV2Client);
}

export { serviceContainer };
