<!--
  A full-width text content block.
-->
<template>
  <div class="relative py-20 bg-white">
    <div class="text-gray-500 font-light grid grid-cols-12">
      <div class="col-span-12 col-start-1 md:col-span-8 md:col-start-3">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>
