import { inject, injectable } from 'inversify';
import { HttpClientInterface } from '../http/httpClientInterface';
import { CatalogClientInterface } from './catalogClientInterface';
import { Catalog } from './types';
import { v4 as uuidv4 } from 'uuid';
import { Brand } from '@/services/integration/enums';

/**
 * @inheritdoc
 */
@injectable()
export class CatalogClient implements CatalogClientInterface {
  constructor (@inject('CatalogHttpClient') private httpClient: HttpClientInterface) { }

  /**
   * @inheritdoc
   */
  public async getCatalog (id: string): Promise<Catalog> {
    // ID must exist.
    if (!id.length) {
      throw new Error(`Invalid ID used when fetching catalog: ${id}`);
    }

    const url = `api/v1/catalogs/${id}`;

    const response = await this.httpClient.get<{ data: Catalog }>(url, {
      headers: {
        'Uniti-Source': 'uniti',
        'Uniti-TransactionId': uuidv4(),
        'Uniti-ConversationId': uuidv4
      }
    });
    return response.data;
  }

  /**
   * @inheritdoc
   */
  public async getCatalogs (brand?: Brand): Promise<Array<Catalog>> {
    let url = 'api/v1/catalogs?excludeInactive=true';

    if (brand) {
      url = url + `&brand=${brand.toLowerCase()}`;
    }

    const response = await this.httpClient.get<{ data: Array<Catalog> }>(url, {
      headers: {
        'Uniti-Source': 'uniti',
        'Uniti-TransactionId': uuidv4(),
        'Uniti-ConversationId': uuidv4
      }
    });
    return response.data;
  }
}
