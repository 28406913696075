import { Brand } from '../../services/integration/enums';
import { Product, ProductType } from '../catalogAdapterInterface';
import { ProductFilterInterface } from './productFilterInterface';

/**
 * Removes internet plans that are not meant to be
 * shown to the end user.
 */
export class UnitiInternetProductFilter implements ProductFilterInterface {
  public supports (type: ProductType, brand: Brand): boolean {
    return type === ProductType.INTERNET && brand === Brand.UNI;
  }

  public run (products: Array<Product>): Array<Product> {
    // Remove 1800 broadband products.
    let filtered = products.filter(product => {
      return !product.description.includes('1300');
    });

    // If there's duplicate speed products, choose the expensive one.
    filtered = filtered.filter(product => {
      let whitelist = true;

      for (const productToCompare of products) {
        if (product.id !== productToCompare.id &&
          product.download === productToCompare.download &&
          product.upload === productToCompare.upload &&
          product.price < productToCompare.price) {
          whitelist = false;
        }
      }

      return whitelist;
    });

    return filtered;
  }
}
