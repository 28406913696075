import { createApp } from 'vue';
import App from './App.vue';
import 'reflect-metadata';
import router from './router';
import store from './store';
import vueServiceContainerPlugin from './plugins/vueServiceContainerPlugin';
import './assets/tailwind.css';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import { serviceContainer } from './inversify.config';
import 'whatwg-fetch';
import 'globalthis';
import smoothscroll from 'smoothscroll-polyfill';
import { createGtm } from '@gtm-support/vue-gtm';
import { install } from 'vue3-recaptcha-v2';

// Polyfill.
smoothscroll.polyfill();

// Vue app.
const app = createApp(App);

// Configure Sentry.
if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    app,
    dsn: 'https://d504dcd4db8a4fd7a42f10d1fe81dac7@sentry01.unitiwireless.com/17',
    release: process.env.VUE_APP_SENTRY_RELEASE,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', /^\//]
      })
    ],
    tracesSampleRate: 0.1
  });
}

app.use(vueServiceContainerPlugin, serviceContainer);
app.use(store);
app.use(router);

let gtmId = '';
switch (process.env.VUE_APP_BRAND_CODE) {
  case 'JAM':
    gtmId = 'GTM-TWM8FF9';
    break;
  case 'ARK':
    gtmId = 'GTM-MHG4D78';
    break;
  case 'BLM':
    gtmId = 'GTM-5SGSZ2H';
    break;

  default:
    break;
}
if (gtmId) {
  app.use(
    createGtm({
      id: gtmId,
      defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
      compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
      enabled: true, // Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
      debug: false, // Whether or not display console logs debugs, turn off in prod (optional)
      loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
      vueRouter: router, // Pass the router instance to automatically sync with router (optional)
      trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
    })
  );
}

app.use(install, {
  sitekey: '6LesNl0qAAAAABdtnjuXP_xlIfBkQS-L5jZY2vbF',
  cnDomains: false // Optional, If you use in China, set this value true
});

app.mount('#app');
