import { injectable } from 'inversify';
import { UtilityServiceInterface } from './utilityServiceInterface';

@injectable()
export class UtilityService implements UtilityServiceInterface {
  public toTitleCase (text: string): string {
    return text.replace(
      /\w\S*/g,
      (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  public formatCurrency (num: number): string {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'AUD'
    });

    return formatter.format(num);
  }
}
