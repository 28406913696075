export enum Carrier {
  OC = 'OC',
  LB = 'LB',
  NB = 'NB',
  OF = 'OF',
  ON = 'ON',
  HW = 'HW',
  HX = 'HX',
  UW = 'UW',
  UFL = 'UFL',
  RT = 'RT',
  WIR = 'WIR',
  SM = 'SM',
  SMP = 'SMP',
}

export enum Brand {
  HIS = 'HIS',
  UNI = 'UNI',
  FUZ = 'FUZ',
  WIR = 'WIR',
  QUA = 'QUA',
  PRE = 'PRE',
  JAM = 'JAM',
  ARK = 'ARK',
  BLM = 'BLM'
}

export enum Technology {
  FTTP = 'FTTP',
  FTTN = 'FTTN',
  FTTH = 'FTTH',
  HFC = 'HFC',
  SAT = 'SAT',
  WIR = 'WIR',
  ETHERNET = 'ETHERNET',
  FTTC = 'FTTC',
  MBB = 'MBB',
  FTTB = 'FTTB',
  SMP = 'SMP',
  VDSL = 'VDSL',
}
