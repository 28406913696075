import { AuthenticationResult, Configuration, PublicClientApplication } from '@azure/msal-browser';
import { injectable } from 'inversify';
import { Account, AuthenticationServiceInterface } from './authenticationServiceInterface';

/**
 * Logs a user into a Microsoft account.
 */
@injectable()
export class MicrosoftAuthenticationService implements AuthenticationServiceInterface {
  private readonly config: Configuration = {
    auth: {
      clientId: '6e9af5a3-4911-4807-ab8f-ca49fb1359bc',
      redirectUri: `${window.location.origin}/authentication`,
      authority: 'https://login.microsoftonline.com/bbabdbf4-0bd7-4aa8-8c5f-243ee6d89498'
    }
  };

  private client: PublicClientApplication;

  constructor () {
    this.client = new PublicClientApplication(this.config);
  }

  public async getAccount (): Promise<Account | null> {
    const accounts = this.client.getAllAccounts();

    // We only support a single account.
    if (accounts.length) {
      const account = accounts.shift();

      if (account) {
        return {
          id: account.homeAccountId,
          name: account.name || account.username,
          email: account.username
        };
      }
    }

    return null;
  }

  public async doLogin (): Promise<Account | null> {
    // Is someone already logged in?
    const account = await this.getAccount();

    if (account) {
      return account;
    }

    const response = await this.client.handleRedirectPromise();
    return await this.handleRedirectResponse(response);
  }

  public async doLogout (): Promise<void> {
    const account = await this.getAccount();

    // Log someone out if they are logged in.
    if (account) {
      const logoutRequest = {
        account: this.client.getAccountByHomeId(account.id)
      };

      if (logoutRequest) {
        this.client.logoutRedirect(logoutRequest);
      }
    }
  }

  private async handleRedirectResponse (response: AuthenticationResult | null): Promise<Account | null> {
    const loginRequest = {
      scopes: ['User.ReadWrite']
    };

    const account = await this.getAccount();

    if (response === null && account === null) {
      // We're at the start of the process and need to call the
      // login redirect.
      this.client.loginRedirect(loginRequest);
    }

    // If the accoutn exists, we've probably been redirected
    // here after login. Return the logged in account.
    return account;
  }
}
