import { injectable } from 'inversify';
import { Brand, Carrier } from '../integration/enums';
import { BrandService } from './brandService';
import { PrivacyInformation, VoipInformation, PlanSelectorProductInformation, DefaultPlanSelectorProducts, FeaturesData, LegalDocuments } from './brandServiceInterface';

/**
 * Brand-specific logic for UNI.
 */
@injectable()
export class BloomBrandService extends BrandService {
  public getName (): Brand {
    return Brand.BLM;
  }

  public getTitle (): string {
    return 'Bloom';
  }

  public getHeroTitle (): string {
    return 'Blooming good internet.';
  }

  public getHomeAnnouncement (): string {
    return '<strong>IMPORTANT: From 12 October 2024</strong>, Bloom Broadband will transfer its customers to Uniti Internet, its supplier of internet services. This will provide continuity of service for its Australian customers with reliable and affordable high-speed internet up to 1Gbps. <br><strong>From 11 September 2024, all new internet service orders will be re-directed to Uniti Internet, or <a class="underline" href="https://unitiinternet.com?from=bloom">click here.</a></strong>';
  }

  public getPrivacyInformation (): PrivacyInformation {
    return {
      brand: 'Bloom',
      brandPlural: "Bloom's",
      brandUrl: 'www.bloom.com',
      policyUrl: 'www.bloom.com/legal',
      privacyOfficerDetails: {
        email: 'privacy@bloombroadband.com.au',
        phone: '1300 712 037',
        businessName: 'Bloom Broadband Pty',
        address: '19 Ben Nevis Grove, Bulleen VIC 3105'
      }
    };
  }

  public getVoipInformation (): VoipInformation {
    return {
      email: 'wifisupport@bloombroadband.com.au',
      phone: '1300 080 571',
      csaAgreementUrl: this.getAssetsBaseUrl() + `/${this.getName()}/customer-service-agreement.pdf`
    };
  }

  public getPlanSelectorProductInformation (carrier?: Carrier): PlanSelectorProductInformation {
    let cisUrl = this.getAssetsBaseUrl() + `/${this.getName()}/critical-information-summary-internet.pdf`;
    if (carrier === 'OC') {
      cisUrl = this.getAssetsBaseUrl() + `/${this.getName()}/critical-information-summary-internet-oc.pdf`;
    }
    if (carrier === 'NB') {
      cisUrl = this.getAssetsBaseUrl() + `/${this.getName()}/critical-information-summary-internet-nb.pdf`;
    }
    return {
      standardFeatures: [
        'Australian Based Support',
        'Open 7 Days'
      ],
      standardLinks: [
        {
          name: 'Critical Information Summary',
          link: cisUrl
        }
      ]
    };
  }

  public removeDefaultPlansFrom (): boolean {
    return true;
  }

  public getDefaultPlanSelectorProducts (): Array<DefaultPlanSelectorProducts> {
    return [{
      id: '',
      name: 'The 25',
      description: 'Perfect for single-person households',
      price: 69,
      typicalEveningSpeed: 24.7,
      download: 25,
      upload: 5
    }, {
      id: '',
      name: 'The 50',
      description: 'Perfect for families and households with multiple users',
      price: 79,
      typicalEveningSpeed: 48.4,
      download: 50,
      upload: 20
    }, {
      id: '',
      name: 'The 100',
      description: 'Perfect for families and media streaming',
      price: 89,
      typicalEveningSpeed: 96.9,
      download: 100,
      upload: 20
    }, {
      id: '',
      name: 'The 250',
      description: 'Perfect for users with large data requirements',
      price: 109,
      typicalEveningSpeed: null,
      download: 250,
      upload: 25
    }, {
      id: '',
      name: 'The 1000',
      description: 'Perfect for users with large data requirements',
      price: 149,
      typicalEveningSpeed: null,
      download: 1000,
      upload: 50
    }];
  }

  public getFeaturesData (): FeaturesData {
    return {
      header: {
        headline: 'What makes our service Blooming Good?',
        tagline: 'We provide, fast reliable, and fair priced internet.'
      },
      features: [{
        name: 'Friendly local support',
        description: 'Our customer service is Australian-based and open 7 days a week for your convenience.'
      }, {
        name: 'Quick and free installation',
        description: 'Our local technical support team will aim to get your service running on the same day.'
      }, {
        name: 'Flexibility',
        description: 'You can choose a month-to-month contract option, 12 or 24-months.'
      }, {
        name: 'Unlimited data',
        description: 'All our plans include unlimited access to the internet, all you need to do is pick the speed you need.'
      }]
    };
  }

  public getSupportPhoneNumber (): string {
    return '1300 080 571';
  }

  public getSalesPhoneNumber (): string {
    return '1300 712 037';
  }

  public getRelocationPhoneNumber (): string {
    return '1300 080 571';
  }

  public getSupportEmail (): string {
    return 'wifisupport@bloombroadband.com.au';
  }

  public getLegalDocuments (): LegalDocuments {
    return {
      termsOfService: [
        {
          name: 'Customer Service Agreement',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/customer-service-agreement.pdf`
        },
        {
          name: 'Direct Debit Service Request Agreement',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/direct-debit-service-request-agreement.pdf`
        },
        {
          name: 'Terms & Conditions',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/terms-and-conditions.pdf`
        }
      ],
      policies: [
        {
          name: 'Billing Policy',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/billing-policy.pdf`
        },
        {
          name: 'Financial Hardship Policy',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/financial-hardship-policy.pdf`
        },
        {
          name: 'Financial Hardship Policy Summary',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/financial-hardship-policy-summary.pdf`
        }
      ],
      formsAndGuides: [
        {
          name: 'Authorised Representative',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/authorised-representative.pdf`
        },
        {
          name: 'Two Factor Authentication',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/two-factor-authentication.pdf`
        }
      ],
      legacy: []
    };
  }

  public getAddress (): string {
    return '19 Ben Nevis Grove, Bulleen VIC 3105';
  }
}
