import { injectable } from 'inversify';
import { AutoCompletedAddress } from './types';
import { SqClient } from './sqClient';

/**
 * @inheritdoc
 */
@injectable()
export class ArklifeSqClient extends SqClient {
  /**
   * @inheritdoc
   */

  private static developmentAddresses: { data: AutoCompletedAddress[] } = { data: [] };

  public async autoCompleteAddress (partialAddress: string): Promise<Array<AutoCompletedAddress>> {
    // Must provide the address parameter.
    if (!partialAddress || !partialAddress.length) {
      throw new Error('A partial address must be supplied to SqClient::autoCompleteAddress.');
    }

    const developments = ['28 ROBERTSON STREET'];

    // Url endpoint to receive all addresses from SQ for a specific development
    let url = 'api/v1/address?';

    // building request url
    developments.forEach(development => {
      url = url + '&development[]=' + development.toUpperCase();
    });

    if (ArklifeSqClient.developmentAddresses.data.length === 0) {
      ArklifeSqClient.developmentAddresses = await this.httpClient.get(url);
    }

    const filtered = ArklifeSqClient.developmentAddresses.data.filter(element => {
      return element.address.toLowerCase().includes(partialAddress.toLowerCase());
    });

    return filtered;
  }
}
