import { Carrier, Technology } from '../enums';
import { inject, injectable } from 'inversify';
import { SqClientInterface } from './sqClientInterface';
import { AutoCompletedAddress, ServiceQualification } from './types';
import { ServiceQualificationClient } from '@uniti-internet/sq-js-client/dist/serviceQualificationClient';
import { ServiceQualification as UnitiServiceQualification } from '@uniti-internet/sq-js-client/dist/util/types';

/**
 * @inheritdoc
 */
@injectable()
export class SqV2Client implements SqClientInterface {
  constructor (@inject('SqV2HttpClient') protected httpClient: ServiceQualificationClient) { }

  /**
   * @inheritdoc
   */
  public async searchAddress (address: string | null, gnaf: string | null, carrier: Carrier | null, carrierId: string | null): Promise<Array<ServiceQualification>> {
    // Must provide at least one parameter, and the carrier must be
    // supplied with the carrier id.
    if (!address && !gnaf && ((!carrier && !carrierId) || (carrier && !carrierId) || (!carrier && carrierId))) {
      throw new Error('Must provide at least one parameter for service qualification to search on, and carrier and carrierId must be provided together.');
    }

    // This implementation doesn't support gnaf.
    if (gnaf) {
      throw new Error('SqV2Client::searchAddress doesn\'t support gnaf.');
    }

    // Request and format based on input.
    let adapted: ServiceQualification[] = [];
    if (carrier && carrierId) {
      const response = await this.httpClient.sqCarrier(carrier, carrierId);
      adapted = response ? this.adaptSq([response]) : [];
    } else if (address) {
      const response = await this.httpClient.sqText(address);
      adapted = this.adaptSq(response);
    } else {
      return [];
    }

    console.log(adapted);

    // Unwrap data.
    return adapted;
  }

  /**
   * @inheritdoc
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async autoCompleteAddress (partialAddress: string, carrier?: Carrier): Promise<Array<AutoCompletedAddress>> {
    // Must provide the address parameter.
    if (!partialAddress || !partialAddress.length) {
      throw new Error('A partial address must be supplied to SqClient::autoCompleteAddress.');
    }

    // Request and format.
    const response = await this.httpClient.autocomplete(partialAddress);

    // Unwrap data.
    return response.map((address) => {
      return {
        ...address,
        carrier: Carrier[address.carrier as keyof typeof Carrier]
      };
    });
  }

  public async getService (id: string | AutoCompletedAddress): Promise<Array<ServiceQualification>> {
    if (typeof id === 'string' || id.identifiers !== undefined) {
      throw new Error('An AutoCompletedAddress must be supplied to SqV2Client::getService.');
    }

    // Ensure TypeScript knows the identifiers exist.
    const request = {
      ...id,
      identifiers: (id.identifiers) ? id.identifiers : []
    };

    // Request and format.
    const response = await this.httpClient.sqAddress(request);
    const adapted = this.adaptSq(response);

    console.log(adapted);

    return adapted;
  }

  private adaptSq (adaptee: UnitiServiceQualification[]): ServiceQualification[] {
    return adaptee.map((sq) => {
      return {
        ...sq,
        carrier: Carrier[sq.carrier as keyof typeof Carrier],
        technology: Technology[sq.technology as keyof typeof Technology]
      };
    });
  }
}
