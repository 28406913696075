import { createStore } from 'vuex';
import { Service } from '@/adapters/serviceQualificationAdapterInterface';
import { Catalog } from '@/adapters/catalogAdapterInterface';
import { Account } from '@/services/authentication/authenticationServiceInterface';

type State = {
  serviceQualification: Service | null,
  catalog: Catalog | null,
  account: Account | null,
  salesPerson: string | null,
  paymentAttempts: number
}

/**
 * Store stuff here that needs to be persisted through a page change using
 * vue-router.
 *
 * And nothing else :P
 */
export default createStore<State>({
  state: {
    serviceQualification: null,
    catalog: null,
    account: null,
    salesPerson: null,
    paymentAttempts: 0
  },
  mutations: {
    setServiceQualification (state, service: Service) {
      state.serviceQualification = service;
    },

    setCatalog (state, catalog: Catalog) {
      state.catalog = catalog;
    },

    setAccount (state, account: Account) {
      state.account = account;
    },

    setSalesPerson (state, email: string) {
      state.salesPerson = email;
    },

    setPaymentAttempt (state, num: number) {
      state.paymentAttempts = num;
    }
  },
  actions: {},
  modules: {}
});
