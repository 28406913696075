import { injectable } from 'inversify';
import { Brand } from '../integration/enums';
import { BrandService } from './brandService';
import { PrivacyInformation, VoipInformation, PlanSelectorProductInformation, DefaultPlanSelectorProducts, FeaturesData } from './brandServiceInterface';

/**
 * Brand-specific logic for UNI.
 */
@injectable()
export class JambaBrandService extends BrandService {
  public getName (): Brand {
    return Brand.JAM;
  }

  public getTitle (): string {
    return 'Jamba, Powered by Uniti';
  }

  public getHeroTitle (): string {
    return "It's Easy to Jump Online with Jamba";
  }

  public getHomeAnnouncement (): string {
    return '<strong>IMPORTANT: From 27 March 2024, Jamba powered by Uniti</strong> will be merged with its parent brand Uniti Internet, to provide Australians with simple and reliable, high-speed internet. Offering a range of home and business plans, Uniti can connect you to super fast broadband up to 1Gb at an affordable price.';
  }

  public getPrivacyInformation (): PrivacyInformation {
    return {
      brand: 'Jamba',
      brandPlural: "Jamba's",
      brandUrl: 'www.jamba.poweredbyuniti.com',
      policyUrl: 'www.jamba.poweredbyuniti.com/legal',
      privacyOfficerDetails: {
        email: 'privacy@unitiwireless.com',
        phone: '1300 070 925',
        businessName: 'Uniti Group Limited',
        address: 'Level 1 / 44 Currie Street, Adelaide SA 5000'
      }
    };
  }

  public getVoipInformation (): VoipInformation {
    return {
      email: 'support@jamba.com.au',
      phone: '1300 847 201',
      csaAgreementUrl: 'https://www.unitiwireless.com/pdf/uni/Customer%20Service%20Agreement.pdf'
    };
  }

  public getPlanSelectorProductInformation (): PlanSelectorProductInformation {
    return {
      standardFeatures: [
        'Australian Based Support',
        'Open 7 Days'
      ],
      standardLinks: [
        {
          name: 'Data Usage Information',
          link: this.getAssetsBaseUrl() + `/${this.getName()}/data-usage-information.pdf`
        },
        {
          name: 'Critical Information Summary',
          link: this.getAssetsBaseUrl() + `/${this.getName()}/critical-information-summary-internet.pdf`
        },
        {
          name: 'Key Fact Sheet',
          link: this.getAssetsBaseUrl() + `/${this.getName()}/key-fact-sheet.pdf`
        }
      ]
    };
  }

  public getDefaultPlanSelectorProducts (): Array<DefaultPlanSelectorProducts> {
    return [{
      id: '',
      name: 'The 25',
      description: 'Perfect for single-person households',
      price: 59.95,
      typicalEveningSpeed: 24.7,
      download: 25,
      upload: 5
    }, {
      id: '',
      name: 'The 50',
      description: 'Perfect for families and households with multiple users',
      price: 69.95,
      typicalEveningSpeed: 48.4,
      download: 50,
      upload: 20
    }, {
      id: '',
      name: 'The 100',
      description: 'Perfect for families and media streaming',
      price: 89.95,
      typicalEveningSpeed: 96.9,
      download: 100,
      upload: 20
    }];
  }

  public getFeaturesData (): FeaturesData {
    return {
      header: {
        headline: 'What sets us apart.',
        tagline: 'Your internet — from a company that believes everyone deserves fast, reliable, fairly priced internet.'
      },
      features: [{
        name: 'Friendly local support',
        description: 'We like to keep it old-school. No lengthy directories or bots, just good old-fashioned customer service!'
      }, {
        name: 'Quick and free installation',
        description: 'Our friendly, local install technicians aim to have you up and running on the same day.'
      }, {
        name: 'Flexible contracts',
        description: 'Our flexible contracts allow you to connect on a month-to-month, 12 or 24 month term.'
      }, {
        name: 'Unlimited data',
        description: 'Unlimited data available for all Broadband plans. Use your data when you want and how you want, no peak or off-peak periods apply.'
      }]
    };
  }

  public getSupportPhoneNumber (): string {
    return '1300 279 021';
  }

  public getSalesPhoneNumber (): string {
    return '1300 070 925';
  }

  public getRelocationPhoneNumber (): string {
    return '1300 279 021';
  }

  public getSupportEmail (): string {
    return 'support@jamba.com.au';
  }

  public getAddress (): string {
    return 'Level 1 / 44 Currie Street, Adelaide SA 5000';
  }
}
