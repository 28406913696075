import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Landing from '../views/Landing.vue';
import SignUp from '../views/SignUp.vue';
import Privacy from '../views/Privacy.vue';
import Legal from '../views/Legal.vue';
import Confirm from '../views/Confirm.vue';
import Authentication from '../views/Authentication.vue';
import NotFound from '../views/NotFound.vue';
import NotServiceable from '../views/NotServiceable.vue';
import CsgWaiverScript from '../views/CsgWaiverScript.vue';
import Redirect from '../views/Redirect.vue';

// Here is where we map routes to pages. A page is defined as a
// Vue component in the '@/views/...' directory.
const routes: Array<RouteRecordRaw> = [
  { path: '/', name: 'landing', component: Landing },
  { path: '/sign-up', name: 'sign-up', component: SignUp },
  { path: '/privacy', name: 'privacy', component: Privacy },
  { path: '/legal', name: 'legal', component: Legal },
  { path: '/confirm', name: 'confirm', component: Confirm },
  { path: '/not-serviceable', name: 'not-serviceable', component: NotServiceable },
  { path: '/authentication', name: 'authentication', component: Authentication },
  { path: '/:pathMatch(.*)*', name: 'not-found', component: NotFound },
  { path: '/csg-waiver-script', name: 'csg-waiver-script', component: CsgWaiverScript },
  { path: '/uniti', name: 'redirect', component: Redirect }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      // Has a hash that is not part of Microsoft authentication.
      if (to.hash && to.hash.indexOf('#code=') === -1) {
        return { el: to.hash, behavior: 'smooth' };
      }
      return { left: 0, top: 0 };
    }
  }
});

export default router;
