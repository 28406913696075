import { injectable } from 'inversify';
import { Brand } from '../../services/integration/enums';
import { ProductType } from '../catalogAdapterInterface';
import { ProductFilterFactoryInterface } from './productFilterFactoryInterface';
import { ProductFilterInterface } from './productFilterInterface';
import { HardwareProductFilter } from './hardwareProductFilter';
import { UnitiInternetProductFilter } from './unitiInternetProductFilter';
import { UnitiFeeProductFilter } from './unitiFeeProductFilter';

@injectable()
export class ProductFilterFactory implements ProductFilterFactoryInterface {
  private filters = [
    new UnitiInternetProductFilter(),
    new HardwareProductFilter(),
    new UnitiFeeProductFilter()
  ];

  public getFilters (type: ProductType, brand: Brand): Array<ProductFilterInterface> {
    return this.filters.filter(filter => filter.supports(type, brand));
  }
}
