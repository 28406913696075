import { BrandServiceInterface } from '../services/brand/brandServiceInterface';
import { CatalogProductOffering } from '../services/integration/clients/types';
import { Brand } from '../services/integration/enums';
import { IntegrationFacadeInterface } from '../services/integration/integrationFacadeInterface';
import { inject, injectable } from 'inversify';
import { Catalog, Product, ProductType } from './catalogAdapterInterface';
import { Service } from './serviceQualificationAdapterInterface';
import { ProductFilterFactoryInterface } from '@/adapters/productFilters/productFilterFactoryInterface';

@injectable()
export class CatalogAdapter {
  constructor (
    @inject('IntegrationFacadeInterface') private integrationFacade: IntegrationFacadeInterface,
    @inject('BrandServiceInterface') private brandService: BrandServiceInterface,
    @inject('ProductFilterFactoryInterface') private productFilterFactory: ProductFilterFactoryInterface
  ) { }

  public async getCatalog (brand: Brand): Promise<Array<Catalog>> {
    const catalogs = await this.integrationFacade.getCatalogs();

    const formattedCatalogs: Array<Catalog> = [];
    for (const catalog of catalogs) {
      if (catalog.category.productOffering.length) {
        const formattedCatalog: Catalog = {
          id: catalog.id,
          type: catalog.catalogType,
          carrier: catalog.name,
          brand: this.getBrandFromProduct(catalog.category.productOffering[0]),
          products: []
        };

        formattedCatalog.products = await this.formatProducts(catalog.category.productOffering);
        formattedCatalogs.push(formattedCatalog);
      }
    }

    return formattedCatalogs.filter(catalog => catalog.brand === brand);
  }

  public async getPlans (service: Service): Promise<Array<Product>> {
    const serviceQualification = await this.integrationFacade.doServiceQualification(service.id, null, null, null);
    const plans = await this.integrationFacade.getPlansForServiceQualification(serviceQualification[0]);

    // Return products for the website's brand.
    let formatted = await this.formatProducts(plans);
    formatted = formatted.filter(product => product.brand === this.brandService.getName());

    // Run any custom filters on the products.
    formatted = this.runFiltersOnProducts(formatted, service, ProductType.INTERNET);

    return formatted.sort((a, b) => {
      return (a.price < b.price) ? -1 : 1;
    });
  }

  public async getHardware (service: Service): Promise<Array<Product>> {
    const serviceQualification = await this.integrationFacade.doServiceQualification(service.id, null, null, null);
    const hardware = await this.integrationFacade.getHardwareForServiceQualification(serviceQualification[0]);

    // Return products for the website's brand.
    let formatted = await this.formatProducts(hardware);
    formatted = formatted.filter(product => product.brand === this.brandService.getName());

    // Run any custom filters on the products.
    // formatted = this.runFiltersOnProducts(formatted, service, ProductType.HARDWARE);

    return formatted.sort((a, b) => {
      return (a.price < b.price) ? -1 : 1;
    });
  }

  public async getVoice (service: Service): Promise<Array<Product>> {
    const serviceQualification = await this.integrationFacade.doServiceQualification(service.id, null, null, null);
    const voice = await this.integrationFacade.getVoiceForServiceQualification(serviceQualification[0]);

    // Return products for the website's brand.
    const formatted = await this.formatProducts(voice);
    return formatted.filter(product => product.brand === this.brandService.getName()).sort((a, b) => {
      return (a.price < b.price) ? -1 : 1;
    });
  }

  public async getFees (service: Service): Promise<Array<Product>> {
    const serviceQualification = await this.integrationFacade.doServiceQualification(service.id, null, null, null);
    const fees = await this.integrationFacade.getFeesForServiceQualification(serviceQualification[0]);

    // Return products for the website's brand. This will also return the
    // new development cost fee if the service requires it.
    let formatted = await this.formatProducts(fees);
    formatted = formatted.filter(product => product.brand === this.brandService.getName())
      .filter(product => product.code !== 'NDC' || service.requiresNewDevelopmentCost);

    // Run any custom filters on the products.
    const productFilters = this.productFilterFactory.getFilters(ProductType.FEE, this.brandService.getName());
    for (const filter of productFilters) {
      formatted = filter.run(formatted, service);
    }

    return formatted.sort((a, b) => b.price - a.price);
  }

  private async formatProducts (products: Array<CatalogProductOffering>): Promise<Array<Product>> {
    const formattedProducts: Array<Product> = [];

    for (const product of products) {
      formattedProducts.push({
        id: product.id,
        name: product.name,
        description: product.description,
        isSellable: product.isSellable,
        symbillId: this.getProductCharacteristic(product, 'SymbillId'),
        universeId: this.getProductCharacteristic(product, 'UniverseId'),
        download: this.getProductCharacteristic(product, 'Download'),
        upload: this.getProductCharacteristic(product, 'Upload'),
        typicalEveningSpeed: this.getProductCharacteristic(product, 'TypicalEveningSpeed'),
        price: this.getPriceFromProduct(product),
        brand: this.getBrandFromProduct(product),
        type: await this.getTypeForProduct(product.id),
        term: this.getProductCharacteristic(product, 'Term'),
        code: this.getProductCharacteristic(product, 'Code')
      });
    }

    return formattedProducts;
  }

  private async getTypeForProduct (id: string): Promise<ProductType> {
    const catalogs = await this.integrationFacade.getCatalogs();

    // To get the type for a product, we have to go back through the
    // catalog. Kinda gross, but hey.
    const catalog = catalogs.find(catalog => {
      for (const product of catalog.category.productOffering) {
        if (product.id === id) {
          return true;
        }
      }

      return false;
    });

    const typeStr = (catalog) ? catalog.catalogType : '';
    return ProductType[typeStr.toUpperCase() as ('HARDWARE' | 'INTERNET' | 'VOICE' | 'FEE')];
  }

  private getProductCharacteristic <T> (product: CatalogProductOffering, characteristic: string): T | null {
    const characteristicObject = product.prodSpecCharValueUse.find(o => o.name === characteristic);

    if (
      characteristicObject &&
      characteristicObject.productSpecCharacteristicValue &&
      characteristicObject.productSpecCharacteristicValue.length &&
      characteristicObject.productSpecCharacteristicValue[0]
    ) {
      return characteristicObject.productSpecCharacteristicValue[0].value as T;
    }

    return null;
  }

  private getPriceFromProduct (product: CatalogProductOffering): number {
    if (product.productOfferingPrice.length) {
      return product.productOfferingPrice[0].price.amount;
    } else {
      return 0;
    }
  }

  private getBrandFromProduct (product: CatalogProductOffering): Brand {
    const brand = product.productSpecification.brand;

    // Map to brand enum.
    if (brand === 'Jamba powered by Uniti') {
      return Brand.JAM;
    } else if (brand === 'Harbour ISP') {
      return Brand.HIS;
    } else if (brand === 'FuzeNet') {
      return Brand.FUZ;
    } else if (brand === 'QuantumNet powered by Uniti') {
      return Brand.QUA;
    } else if (brand === 'Arklife') {
      return Brand.ARK;
    } else if (brand === 'Bloom') {
      return Brand.BLM;
    } else {
      // Default to Uniti.
      return Brand.UNI;
    }
  }

  private runFiltersOnProducts (products: Product[], service: Service, type: ProductType) {
    let filtered = products;

    const productFilters = this.productFilterFactory.getFilters(type, this.brandService.getName());
    for (const filter of productFilters) {
      filtered = filter.run(filtered, service);
    }

    return filtered;
  }
}
