import { App, inject, InjectionKey } from 'vue';
import { Container, interfaces } from 'inversify';

/**
 * A VueJS plugin that provides service container bindings to the
 * provide/inject API.
 */
export default {
  install: (app: App<Element>, serviceContainer: VueServiceContainer): void => {
    // Provide bindings.
    for (const binding of serviceContainer.getBindings()) {
      app.provide(binding, serviceContainer.get(binding));
    }
  }
};

/**
 * Inject a variable into a Vue component based on a service identifier. This
 * function will error if the variable cannot be resolved.
 *
 * @param key The service identifier.
 * @param fallback Returns this value if the injection cannot be resolved.
 *
 * @returns The injection value.
 */
export const injectStrict = <T>(key: string | InjectionKey<T | undefined>, fallback?: T): T => {
  const resolved = inject(key, fallback);

  if (!resolved) {
    const description = (typeof key === 'string') ? key : key.description;
    throw new Error(`Could not resolve Vue injection: ${description}`);
  }

  return resolved;
};

/**
 * @inheritdoc
 */
export class VueServiceContainer extends Container {
    private bindings: string[] = [];

    /**
     * @inheritdoc
     */
    public bind<T> (serviceIdentifier: string): interfaces.BindingToSyntax<T> {
      this.bindings.push(serviceIdentifier);

      return super.bind<T>(serviceIdentifier);
    }

    /**
     * Get the service identifiers of all bindings in the service container.
     *
     * @returns The service identifiers.
     */
    public getBindings (): string[] {
      return this.bindings;
    }
}
