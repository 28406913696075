import { IntegrationFacadeInterface } from '../services/integration/integrationFacadeInterface';
import { inject, injectable } from 'inversify';
import { Address, AutocompleteAddressAdapterInterface } from './autocompleteAddressAdapterInterface';

@injectable()
export class AutocompleteAddressAdapter implements AutocompleteAddressAdapterInterface {
  constructor (
    @inject('IntegrationFacadeInterface') private integrationFacade: IntegrationFacadeInterface
  ) { }

  public async autocompleteAddress (partialAddress: string): Promise<Array<Address>> {
    if (partialAddress === '') {
      return [];
    }

    const addresses = await this.integrationFacade.getAddresses(partialAddress);
    const formattedAddresses: Array<Address> = [];

    for (const address of addresses) {
      formattedAddresses.push({
        id: address.id,
        address: this.capitalizeFirstLetter(address.address),
        carrierId: address.carrier_id,
        carrier: address.carrier
      });
    }

    return formattedAddresses;
  }

  private capitalizeFirstLetter (str: string) {
    str = str.toLowerCase();
    const states = ['SA', 'WA', 'NSW', 'ACT', 'TAS', 'NT', 'QLD', 'VIC'];
    return str.split(' ').map((word) => {
      if (states.includes(word.toUpperCase())) {
        return word.toUpperCase();
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
    }).join(' ');
  }
}
