import { IntegrationFacadeInterface } from '../services/integration/integrationFacadeInterface';
import { inject, injectable } from 'inversify';
import { Service, ServiceQualificationAdapterInterface } from './serviceQualificationAdapterInterface';
import { differenceInCalendarDays } from 'date-fns';
import { Address } from './autocompleteAddressAdapterInterface';

@injectable()
export class ServiceQualificationAdapter implements ServiceQualificationAdapterInterface {
  constructor (
    @inject('IntegrationFacadeInterface') private integrationFacade: IntegrationFacadeInterface
  ) { }

  public async serviceQualification (address: Address): Promise<Service | null> {
    const serviceQualifications = await this.integrationFacade.doServiceQualification(null, address.address.toUpperCase(), address.carrier, address.carrierId);

    if (serviceQualifications.length) {
      return {
        id: serviceQualifications[0].id,
        address: this.capitalizeFirstLetter(serviceQualifications[0].address),
        carrier: serviceQualifications[0].carrier,
        locationId: serviceQualifications[0].carrier_id,
        technology: serviceQualifications[0].technology,
        requiresNewDevelopmentCost: serviceQualifications[0].ndc !== 0,
        development: serviceQualifications[0].development_name,
        suburb: serviceQualifications[0].suburb,
        state: serviceQualifications[0].state,
        rtcDays: this.rtcDateToDays(serviceQualifications[0].rtc_date)
      };
    } else {
      return null;
    }
  }

  private rtcDateToDays (date: string): number {
    if (date === null) return 0;
    let days = 0;

    try {
      days = differenceInCalendarDays(
        new Date(),
        new Date(date)
      );
    } catch {
      days = 0;
    }

    return days;
  }

  private capitalizeFirstLetter (str: string) {
    str = str.toLowerCase();
    const states = ['SA', 'WA', 'NSW', 'ACT', 'TAS', 'NT', 'QLD', 'VIC'];
    return str.split(' ').map((word) => {
      if (states.includes(word.toUpperCase())) {
        return word.toUpperCase();
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
    }).join(' ');
  }
}
