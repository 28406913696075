import { Carrier } from '../../services/integration/enums';
import { Product, ProductType } from '../catalogAdapterInterface';
import { Service } from '../serviceQualificationAdapterInterface';
import { ProductFilterInterface } from './productFilterInterface';

/**
 * Only returns hardware that is suitable for the SQ.
 */
export class HardwareProductFilter implements ProductFilterInterface {
  public supports (type: ProductType): boolean {
    return type === ProductType.HARDWARE;
  }

  public run (products: Array<Product>, service: Service): Array<Product> {
    // Filter hardware based on technology.
    if (service.carrier === Carrier.HX) {
      return products.filter(hardware => hardware.name.toUpperCase().includes('NOKIA'));
    } else if (service.carrier === Carrier.HW) {
      return products.filter(hardware => hardware.name.toUpperCase().includes('NL1901ACV'));
    } else {
      return products.filter(hardware => hardware.name.toUpperCase().includes('NF18MESH'));
    }
  }
}
