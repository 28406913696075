import { Product } from '@/adapters/catalogAdapterInterface';
import { Service } from '@/adapters/serviceQualificationAdapterInterface';
import { Promotion } from '@uniti-internet/promotions-js-client/dist/utilities/types';

export type Customer = {
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: number | null,
  companyName: string,
  abnNumber: number | null,
  ccNumber: number | null,
  ccName: string | null,
  ccExpiry: string | null,
  ccCvc: string | null,
  ddAccountNumber: string,
  ddAccountBsb: string,
  token?: string, // BPoint token.
  activationDate: string,
  crmId: string | null // Hubspot ID
  formSelectionOptions: {
    businessAccount: boolean,
    paymentMethod: string,
    confirmAddressToggle: boolean,
    termsAgreementToggle: boolean,
    csgWaiverToggle: boolean,
    ddAgreementToggle: boolean,
    pickActivationDate: boolean
  },
}

export class ValidationError extends Error { }

/**
 * Strategies for ordering a service.
 */
export interface OrderServiceInterface {
  /**
   * Builds and sends an API call to the shopping cart to submit an order, but
   * does not complete the cart, or add payment details.
   *
   * @throws ValidationError when the cart fails to submit for a reason.
   *
   * @param service
   * @param products
   * @param customer
   * @param validPromotion
   * @param cartId Optional. Use foir if you are replacing or updating a cart.
   *
   * @returns The cart id, or an empty string if it wasn't saved.
   */
  submitOrderToShoppingCart(service: Service, products: Array<Product>, customer: Customer, validPromotion?: Promotion, cartId?: string): Promise<string>;

  /**
   * Builds and sends an API call to the shopping cart to submit an order, adds
   * the payment details, and completes the cart.
   *
   * @throws ValidationError when the cart fails to submit for a reason.
   *
   * @param service
   * @param products
   * @param customer
   * @param validPromotion
   * @param cartId Optional. Use foir if you are replacing or updating a cart.
   *
   * @returns The cart id, or an empty string if it wasn't saved.
   */
  submitOrderToShoppingCartWithPayment(service: Service, products: Array<Product>, customer: Customer, validPromotion?: Promotion, cartId?: string): Promise<string>;

  /**
   * Calculates the activation fee based on what contract the custoemr has
   * selected. This does not include the new development cost.
   *
   * @param products
   */
  calculateActivationFee(products: Array<Product>): number;

  /**
   * Calculate the price to the first invoice. This does include the new
   * development cost. Uses discounted prices if promoValid is true.
   *
   * @param products
   */
  calculateFirstInvoiceTotal(products: Array<Product>, isPromoValid: boolean, validPromotion?: Promotion): number;

  /**
   * Calculates the estimated minimum cost over the length of the customer's
   * selected contract.
   *
   * @param products
   */
  calculateMinimumCost(products: Array<Product>, isPromoValid: boolean, validPromotion?: Promotion): number;
}
