import { Brand } from '../services/integration/enums';
import { Service } from './serviceQualificationAdapterInterface';

// These map to the catalog types in the catalog.
export enum ProductType {
  HARDWARE = 'hardware',
  INTERNET = 'internet',
  VOICE = 'voice',
  FEE = 'fee'
}

export type Product = {
  id: string,
  name: string,
  description: string,
  isSellable: boolean,
  symbillId: number | null,
  universeId: number | null,
  download: number | null,
  upload: number | null,
  typicalEveningSpeed: number | null,
  price: number,
  brand: Brand,
  type: ProductType,
  term: number | null;
  code: string | null;
  discountedPrice?: number;
  discounted?: boolean;
  displayDiscount?: boolean;
}

export type Catalog = {
  id: string,
  type: string,
  carrier: string,
  brand: Brand,
  products: Array<Product>
}

/**
 * Adapts the catalog api integration to a format the website can use.
 */
export interface CatalogAdapterInterface {
  /**
   * Get catalogs for brand.
   *
   * @param brand
   */
  getCatalog(brand: Brand): Promise<Array<Catalog>>;

  /**
   * Get plans for an SQ.
   *
   * @param brand
   */
  getPlans (service: Service): Promise<Array<Product>>

  /**
   * Get hardware for an SQ.
   *
   * @param brand
   */
  getHardware (service: Service): Promise<Array<Product>>

  /**
   * Get voice plans for an SQ.
   *
   * @param brand
   */
  getVoice (service: Service): Promise<Array<Product>>

  /**
   * Get potential fees for an SQ.
   *
   * @param brand
   */
  getFees (service: Service): Promise<Array<Product>>
}
