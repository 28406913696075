import { Brand } from '../../services/integration/enums';
import { Product, ProductType } from '../catalogAdapterInterface';
import { ProductFilterInterface } from './productFilterInterface';

/**
 * Removes fees that are not meant to be shown to the end user.
 */
export class UnitiFeeProductFilter implements ProductFilterInterface {
  public supports (type: ProductType, brand: Brand): boolean {
    return type === ProductType.FEE && brand === Brand.UNI;
  }

  public run (products: Array<Product>): Array<Product> {
    // Remove 'Shipping Fee' contracts.
    let filtered = products.filter(product => {
      return !product.name.includes('Shipping Fee');
    });

    // If there's duplicate fee products, choose the expensive one.
    filtered = filtered.filter(product => {
      let whitelist = true;

      for (const productToCompare of products) {
        if (product.id !== productToCompare.id &&
          product.name === productToCompare.name &&
          product.price < productToCompare.price) {
          whitelist = false;
        }
      }

      return whitelist;
    });

    return filtered;
  }
}
