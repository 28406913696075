import { helpers } from '@vuelidate/validators';

class CcValidations {
  public numberValidation (num: number) : boolean {
    if (!helpers.req(num)) return true;

    const luhnCheck = (num: number) => {
      const arr = (num + '')
        .split('')
        .reverse()
        .map(x => parseInt(x));
      const lastDigit = arr.splice(0, 1)[0];
      let sum = arr.reduce(
        (acc, val, i) => (i % 2 !== 0 ? acc + val : acc + ((val *= 2) > 9 ? val - 9 : val)),
        0
      );
      sum += lastDigit;
      return sum % 10 === 0;
    };

    if (luhnCheck(num)) {
      // AMEX CC
      if (/^3[47][0-9]{13}$/.test(num.toString())) return true;
      // VISA CC
      if (/^4[0-9]{12}(?:[0-9]{3})?$/.test(num.toString())) return true;
      // MASTERCARD CC
      if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(num.toString())) return true;
    }
    return false;
  }

  public expiryValidation (str: string) : boolean {
    if (!helpers.req(str)) return true;
    if (/^(0[1-9]|1[0-2])\/\d{2}$/.test(str)) return true;
    else return false;
  }

  public cvcValidation (str: string) : boolean {
    if (!helpers.req(str)) return true;
    if (/^([0-9]{3}|[0-9]{4})$/.test(str)) return true;
    else return false;
  }
}

export const ccValidations = new CcValidations();
